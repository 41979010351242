import { defineStore } from 'pinia';
import { UiTypes } from '@/types/ui';
import { ref } from 'vue';

export const useUiStore = defineStore('ui', () => {
  const toast = ref<UiTypes.Toast>();
  const infoToast = ref<UiTypes.Toast>();

  const loading = ref(false);
  const csv = ref(false);
  const refreshDropdownKey = ref(0);

  const showToast = (data: UiTypes.Toast) => {
    toast.value = {
      ...data,
      show: true,
      duration: data.duration ?? 5000,
      position: data.position ?? 'top-right',
      buttonText: data.buttonText ?? '',
      pauseOnHover: data.pauseOnHover ?? true,
      onDismiss: data.onDismiss ?? undefined,
      onClick: data.onClick ?? undefined,
      onButtonClick: data.onButtonClick ?? undefined,
    };
  };

  const showInfoToast = (data: UiTypes.Toast) => {
    infoToast.value = {
      ...data,
      show: true,
      duration: data.duration ?? 5000,
      position: data.position ?? 'top-right',
      buttonText: data.buttonText ?? '',
      pauseOnHover: data.pauseOnHover ?? true,
      onDismiss: data.onDismiss ?? undefined,
      onClick: data.onClick ?? undefined,
      onButtonClick: data.onButtonClick ?? undefined,
    };
  };

  const hideInfoToast = () => {
    infoToast.value = {
      show: false,
      message: '',
      onDismiss: undefined,
      onClick: undefined,
      buttonText: '',
      onButtonClick: undefined,
    };
  };

  const hideToast = () => {
    toast.value = {
      show: false,
      message: '',
      onDismiss: undefined,
      onClick: undefined,
      buttonText: '',
      onButtonClick: undefined,
    };
  };

  return {
    toast,
    showToast,
    hideToast,
    refreshDropdownKey,
    loading,
    csv,
    infoToast,
    showInfoToast,
    hideInfoToast,
  };
});

export default useUiStore;
