import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-vue-v3';

class FingerPrint {
  deviceId = '';

  async update() {
    try {
      const { data, getData } = useVisitorData(
        { extendedResult: true },
        { immediate: false },
      );
      await getData();
      this.deviceId = data.value?.visitorId as string;
    } catch (e) {
      console.log('fpjs failure');
    }
  }

  async get() {
    if (!this.deviceId) {
      await this.update();
    }

    return {
      deviceId: this.deviceId,
    };
  }
}

export default new FingerPrint();
