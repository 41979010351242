<template>
  <div class="div-banner-body">
    <div class="div-banner-body__icon">
      <img
        class="div-banner-body__icon_img"
        src="../../../assets/Warning-black.svg"
        alt="warning icon"
      />
    </div>
    <div class="div-banner-body__text">
      {{ $t('reviewWallet.alert') }}
    </div>
    <div class="div-banner-body__review-address-button">
      <Button
        text="Rewiew request"
        variant="outline"
        size="small"
        @click="reviewWallet"
      />
    </div>
    <div
      class="div-banner-body__close"
      @click="collapseBanner(address)"
      @keypress="onKeyPress"
    >
      <img
        class="div-banner-body__close_img"
        src="../../../assets/close-alt.svg"
        alt="close image"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Button } from '@yellowcard/components-library-vue3';
import { defineComponent } from 'vue';
import { usePartnerStore } from '@/store/modules/partner';

export default defineComponent({
  name: 'ReviewWalletBanner',
  components: {
    Button,
  },
  props: {
    address: {
      type: String,
      required: true,
    },
  },
  emits: ['showWalletReviewModal'],
  setup(_, { emit }) {
    const partnerStore = usePartnerStore();

    const reviewWallet = () => {
      emit('showWalletReviewModal');
    };

    const onKeyPress = () => {
      console.log('key pressed');
    };

    const collapseBanner = (address: string) => {
      partnerStore.hideReviewWalletBanner(address);
    };

    return {
      reviewWallet,
      collapseBanner,
      onKeyPress,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/common/styles/_base.scss';
@import '@/common/styles/_colors.scss';

.div-banner-body {
  left: 0px;
  top: 0px;
  bottom: 0px;
  margin-top: 10px;

  /* Red/25 */
  background: $brand-primary-yellow-25;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  height: 3rem;
  width: 98%;
  margin-left: 1%;

  @include media('<tablet') {
    margin-top: 20%;
    margin-left: 2%;
    width: 96%;
  }

  &__icon {
    width: 1%;
    padding-left: 1%;
    display: flex;
    align-items: center;
    height: 50%;

    @include media('<tablet') {
      width: 4%;
    }

    &_img {
      height: 75%;
      width: 100%;
    }
  }

  &__review-address-button {
    width: 40%;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin-left: 16px;

    @include media('<tablet') {
      width: 25%;
      font-size: 8px;
      white-space: nowrap;
      height: 80%;

      > button {
        text-align: center;
      }
    }
  }

  &__text {
    color: #000000;
    width: auto;
    display: flex;
    justify-content: flex-start;
    margin-left: 8px;

    @include media('<tablet') {
      width: 70%;
      font-size: 10px;
      margin-left: 3%;
    }
  }

  &__close {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1%;
    margin-left: auto;

    @include media('<tablet') {
      width: 3%;
    }

    &_img {
      cursor: pointer;
    }
  }
}

.yc--button.__small {
  height: 30px;
  width: 55%;
  background-color: transparent !important;

  @include media('<tablet') {
    height: 90%;
    font-size: 10px;
    width: 90%;
    text-align: center !important;
  }
}
</style>
