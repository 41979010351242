import { createI18n } from 'vue-i18n';
import axios from 'axios';

import langs from './locale';

const i18n = createI18n({
  locale: 'en', // set locale
  messages: langs, // set locale messages
  silentFallbackWarn: true,
});

let loadedLanguages = ['en'];

function setI18nLanguage(lang: string) {
  // i18n.global.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html')?.setAttribute('lang', lang);
  return lang;
}

const loadLanguage = (lang: string) =>
  import(
    /* webpackChunkName: "lang-[request]" */ `@/locale/${lang}.locale.js`
  ).then(messages => {
    i18n.global.setLocaleMessage(lang, messages.default);
    loadedLanguages = [lang];
    return setI18nLanguage(lang);
  });

export function loadLanguageAsync(lang: string) {
  // If the same language
  if (i18n.global.locale === lang || loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }
  // If the language hasn't been loaded yet
  return loadLanguage(lang);
}

export default i18n;
