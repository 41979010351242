import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5aa1c419"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "inactive-container" }
const _hoisted_2 = { class: "inactive-heading" }
const _hoisted_3 = { class: "inactive-subheading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_idle = _resolveComponent("v-idle")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    open: _ctx.isOpen,
    width: 395,
    onCloseDialog: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('closeDialog')))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('inactiveModal.headingText')), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('inactiveModal.caption')), 1),
          _createVNode(_component_v_idle, {
            duration: 10,
            style: {"margin-top":"-1em"},
            onIdle: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logOut()))
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["open"]))
}