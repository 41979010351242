import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-087bc0a1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "dash-main",
  class: "dash-main"
}
const _hoisted_2 = {
  id: "dash-body",
  class: "dash-body"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  id: "container",
  class: "container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashNavigation = _resolveComponent("DashNavigation")!
  const _component_v_idle = _resolveComponent("v-idle")!
  const _component_InactiveModal = _resolveComponent("InactiveModal")!
  const _component_LowBalanceBanner = _resolveComponent("LowBalanceBanner")!
  const _component_ReviewWalletBanner = _resolveComponent("ReviewWalletBanner")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_WalletTopUpModal = _resolveComponent("WalletTopUpModal")!
  const _component_ReviewWallet = _resolveComponent("ReviewWallet")!
  const _component_Snackbar = _resolveComponent("Snackbar")!
  const _component_InfoSnackbar = _resolveComponent("InfoSnackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DashNavigation),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_idle, {
        duration: 300,
        onIdle: _cache[0] || (_cache[0] = ($event: any) => (_ctx.inactive = true)),
        style: {"visibility":"hidden"}
      }),
      (_ctx.inactive)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_InactiveModal, {
              "is-open": _ctx.inactive,
              onCloseDialog: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inactive = false))
            }, null, 8, ["is-open"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.showLowBalanceAlert && !_ctx.userDismissedBanner)
          ? (_openBlock(), _createBlock(_component_LowBalanceBanner, {
              key: 0,
              onShowWalletTopupModal: _ctx.onShowWalletTopupModal
            }, null, 8, ["onShowWalletTopupModal"]))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addressesPendingReview, (address) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: address.id
          }, [
            (_ctx.seeReviewBanner && !_ctx.reviewBannersDismissed[address.address])
              ? (_openBlock(), _createBlock(_component_ReviewWalletBanner, {
                  key: 0,
                  address: address.address,
                  onShowWalletReviewModal: ($event: any) => (_ctx.onShowWalletReviewModal(address))
                }, null, 8, ["address", "onShowWalletReviewModal"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128)),
        _createVNode(_component_router_view),
        _createVNode(_component_WalletTopUpModal, {
          "show-dialog": _ctx.showWalletTopupModal,
          onHideDialog: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showWalletTopupModal = false))
        }, null, 8, ["show-dialog"]),
        _createVNode(_component_ReviewWallet, {
          "show-dialog": _ctx.showWalletReviewModal,
          wallet: _ctx.currentReviewAddress!,
          onCloseDialog: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showWalletReviewModal = false))
        }, null, 8, ["show-dialog", "wallet"])
      ])
    ]),
    (_ctx.toast?.show)
      ? (_openBlock(), _createBlock(_component_Snackbar, {
          key: 0,
          text: _ctx.toast?.message!,
          "button-text": _ctx.toast?.buttonText,
          position: _ctx.toast?.position,
          duration: _ctx.toast?.duration,
          "on-dismiss": _ctx.toast?.onDismiss,
          "on-click": _ctx.toast?.onClick,
          "on-button-click": _ctx.toast?.onButtonClick,
          onDismissed: _cache[4] || (_cache[4] = ($event: any) => (_ctx.uiStore.hideToast()))
        }, null, 8, ["text", "button-text", "position", "duration", "on-dismiss", "on-click", "on-button-click"]))
      : _createCommentVNode("", true),
    (_ctx.infoToast?.show)
      ? (_openBlock(), _createBlock(_component_InfoSnackbar, {
          key: 1,
          text: _ctx.infoToast?.message!,
          "button-text": _ctx.infoToast?.buttonText,
          position: _ctx.infoToast?.position,
          duration: _ctx.infoToast?.duration,
          "on-dismiss": _ctx.infoToast?.onDismiss,
          "on-click": _ctx.infoToast?.onClick,
          "on-button-click": _ctx.infoToast?.onButtonClick,
          onDismissed: _cache[5] || (_cache[5] = ($event: any) => (_ctx.uiStore.hideInfoToast()))
        }, null, 8, ["text", "button-text", "position", "duration", "on-dismiss", "on-click", "on-button-click"]))
      : _createCommentVNode("", true)
  ]))
}