import { createApp, watch } from 'vue';
import { createPinia } from 'pinia';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vidle from 'v-idle-3';
import {
  fpjsPlugin,
  FpjsVueOptions,
} from '@fingerprintjs/fingerprintjs-pro-vue-v3';
import App from './App.vue';
import router from './router';
import i18n from './i18n';

library.add(faCircleInfo);
library.add(faFilter);

const pinia = createPinia();

if (sessionStorage.getItem('state')) {
  pinia.state.value = JSON.parse(sessionStorage.getItem('state') ?? '');
}

watch(
  pinia.state,
  state => {
    sessionStorage.setItem('state', JSON.stringify(state));
  },
  { deep: true },
);
const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(Vidle, {})
  .use(pinia)
  .use(fpjsPlugin, {
    loadOptions: {
      apiKey: process.env.VUE_APP_FPJS_TOKEN,
      endpoint: [process.env.VUE_APP_FPJS_ENDPOINT],
      scriptUrlPattern: [process.env.VUE_APP_FPJS_STRICT_PATTERN_ENDPOINT],
    },
  } as FpjsVueOptions)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');

export default app;
