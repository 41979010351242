<template>
  <div id="app" class="app-container">
    <router-view />
  </div>
</template>

<script lang="ts">
import { setNewEncryptionToken } from '@/utils/csrf';
import { onMounted } from 'vue';

async function setToken() {
  await setNewEncryptionToken();
  setInterval(async () => {
    await setNewEncryptionToken();
  }, 3600000);
}

export default {
  name: 'App',
  setup() {
    onMounted(async () => {
      document.title = 'Yellow Card Partners Dashboard';
      await setNewEncryptionToken();
      /*
       * We use the current hour to generate the CSRF token
       * This ensures it auto refreshes at the top if every hour
       */
      const curHour = new Date().getHours();
      const nextHour = new Date();
      nextHour.setHours(curHour + 1);
      nextHour.setMinutes(0);
      nextHour.setSeconds(0);
      const msToNextHour = nextHour.getTime() - new Date().getTime();
      setTimeout(async () => {
        await setToken();
      }, msToNextHour);
    });
  },
};
</script>
<style lang="scss">
@import '@/common/styles/_base.scss';
@import '@yellowcard/components-library-vue3/lib/index.css';

.app-container {
  background-size: contain !important;
  overflow-x: hidden;
}
.date-picker-container .dp__input {
  line-height: 2.1rem;
}

.date-picker-container .dp__range_start {
  background-color: #492b7c;
}
.date-picker-container .dp__range_end {
  background-color: #492b7c;
}
</style>
