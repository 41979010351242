export namespace SettlementTypes {
  export interface SettlementDetails {
    id: string | any;
    status: string;
    type: string;
    partnerId: string;
    transactionHash?: string;
    internalWalletAddress: string;
    cryptoCurrency: string;
    cryptoAmount: number;
    cryptoFee?: string;
    network?: string;
    provider?: string;
    externalWalletAddress?: string;
    fiatAmountUSD: string | number;
    cryptoRate: number;
    walletBalanceUSD: string | number;
    date: string;
    sessionId?: string;
    sequenceId?: string;
    networkFee?: number;
  }

  export interface GetDataNamedParameters {
    token: string;
    partnerId: string;
    filter?: any;
  }

  export const rawHeaders = [
    'id',
    'createdAt',
    'type',
    'fiatAmountUSD',
    'walletBalanceUSD',
    'status',
    'internalWalletAddress',
    'cryptoCurrency',
    'cryptoAmount',
    'cryptoRate',
    'transactionHash',
    'externalWalletAddress',
    'network',
  ];

  export const headerMapping = {
    id: 'ID',
    createdAt: 'Date',
    type: 'Transaction type',
    fiatAmountUSD: 'fiatAmountUSD',
    walletBalanceUSD: 'walletBalanceUSD',
    status: 'Status',
    cryptoCurrency: 'cryptoCurrency',
    cryptoAmount: 'cryptoAmount',
  };

  export const DisplayHeadersSettlements = [
    'ID',
    'Date',
    'Transaction type',
    'Amount',
    'Status',
    'View',
  ];
  export const TransactionTypeMapping = {
    topup: 'Top up',
    payout: 'Pay out',
  };
}
