export enum RouteNames {
  Home = 'home',
  About = 'about',
  PasswordChanged = 'passwordChanged',
  AccountInactive = 'accountInactive',
  Login = 'login',
  ResetPassword = 'resetPassword',
  TwoFactorAuthentication = 'twoFactorAuthentication',
  Transactions = 'transactions',
  Balance = 'balance',
  APIKeys = 'apiKeys',
  Settings = 'settings',
  RequestedPasswordReset = 'requestedPasswordReset',
  WalletManagement = 'walletManagement',
}

export enum RoutePaths {
  Home = '/',
  About = '/about',
  PasswordChanged = '/password-changed',
  AccountInactive = '/account-inactive',
  Login = '/login',
  ResetPassword = '/reset-password',
  TwoFactorAuthentication = '/two-factor-authentication',
  Transactions = '/transactions',
  Balance = '/balance',
  APIKeys = '/api-keys',
  Settings = '/settings',
  RequestedPasswordReset = '/requested-password-reset',
  WalletManagement = '/wallets',
}

export default { RouteNames, RoutePaths };
