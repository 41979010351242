export namespace CollectionTypes {
  export const rawHeaders = [
    'id',
    'country',
    'convertedAmount',
    'currency',
    'sourceaccountType',
    'status',
    'createdAt',
    'amount',
    'recipientname',
    'sourceaccountNumber',
    'sequenceId',
    'rate',
    'sessionId',
    'requestSource',
    'settlementInfotxHash',
    'settlementInfocryptoCurrency',
    'serviceFeeAmountUSD',
    'networkFeeAmountUSD',
    'settlementStatus',
  ];

  export const headerMapping = {
    id: 'ID',
    country: 'Country',
    convertedAmount: 'convertedAmount',
    status: 'Status',
    sourceaccountType: 'Payment Method',
    createdAt: 'Date',
    currency: 'Currency',
    recipientname: 'sendername',
    sourceaccountNumber: 'destinationaccountNumber',
    requestSource: 'Request Source',
  };

  export const displayHeaders = [
    'ID',
    'Date',
    'Country',
    'Amount',
    'Status',
    'Payment Method',
    'Request Source',
    'View',
  ];
}
