import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import { TransactionTypes } from '@/types/tables/transactions';
import {
  addToUniqueArray,
  getUniqueElements,
  prepareMatchQueryParameter,
  prepareTransactionData,
} from '@/utils/tables';
import { handleQueryConstruction } from '@/utils/searchService';
import PortalService from '@/common/services/portal.service';
import { PortalServiceTypes } from '@/types/portalService';

const paymentsIndexName = process.env.VUE_APP_PAYMENT_INDEX;
export const usePaymentsStore = defineStore('payments', () => {
  const totalPayments = ref(0);
  const allPayments = reactive<any[]>([]);
  const paymentFilters = reactive<TransactionTypes.FilterCategory[]>([
    {
      name: TransactionTypes.Filters.COUNTRY,
      options: [],
      filter: '',
    },
    {
      name: TransactionTypes.Filters.STATUS,
      options: [],
      filter: '',
    },
  ]);

  function resetFilterSelection() {
    const updatedFilterCategories = paymentFilters.map(filterCategory => ({
      ...filterCategory,
      filter: '',
    }));
    paymentFilters.splice(0);
    updatedFilterCategories.forEach(item => paymentFilters.push(item));
  }

  function resetPaymentStore() {
    totalPayments.value = 0;
    allPayments.splice(0);
    resetFilterSelection();
  }

  function setFilterCategory(data: string[], filter: TransactionTypes.Filters) {
    const updatedFilterCategories = paymentFilters.map(filterCategory => {
      if (filterCategory.name === filter) {
        return {
          ...filterCategory,
          options: data,
        };
      }
      return filterCategory;
    });
    paymentFilters.splice(0);
    updatedFilterCategories.forEach(item => paymentFilters.push(item));
  }

  function setAllPayments(data: any[]) {
    allPayments.splice(0);
    data.forEach(item => allPayments.push(item));
  }

  function preparePaymentFilters(data: any[]) {
    const uniqueCountries = getUniqueElements(
      data,
      TransactionTypes.Filters.COUNTRY,
    );
    const existingCountryArray = paymentFilters.find(
      category => category.name === TransactionTypes.Filters.COUNTRY,
    )?.options;

    const uniqueStatuses = getUniqueElements(
      data,
      TransactionTypes.Filters.STATUS,
    );
    const existingStatusArray = paymentFilters.find(
      category => category.name === TransactionTypes.Filters.STATUS,
    )?.options;

    if (!existingCountryArray || !existingStatusArray) {
      throw new Error('required category country not found in filter object');
    }
    let countryArray = addToUniqueArray(existingCountryArray, uniqueCountries);

    countryArray = countryArray.filter(n => n);
    if (existingCountryArray.length !== countryArray.length)
      setFilterCategory(countryArray, TransactionTypes.Filters.COUNTRY);

    let statusArray = addToUniqueArray(existingStatusArray, uniqueStatuses);
    statusArray = statusArray.filter(n => n);
    if (existingStatusArray.length !== statusArray.length)
      setFilterCategory(statusArray, TransactionTypes.Filters.STATUS);
  }

  async function getPaymentData(
    partnerId: string,
    token: string,
    filterQuery: TransactionTypes.FilterQuery,
    filterTransactions: TransactionTypes.FilterCategory[],
  ): Promise<any[]> {
    const request = {
      startIndex: filterQuery.startIndex,
      size: 10,
      sort: [{ createdAt: 'desc' }],
      should: [],
      must: prepareMatchQueryParameter(
        partnerId,
        filterTransactions,
        TransactionTypes.DisplayTable.PAYMENTS,
      ),
      filter: filterQuery,
    };
    let rawPayments: PortalServiceTypes.GetDataResponse;
    try {
      const query = handleQueryConstruction(request, `${paymentsIndexName}`);
      rawPayments = await PortalService.GetData(token, query);
    } catch (e: any) {
      console.error(e.message);
      throw new Error('Could not retrieve payment records');
    }

    const cleanedPaymentData = prepareTransactionData(
      rawPayments.data,
      TransactionTypes.TransactionTable.PAYMENTS,
    );
    totalPayments.value = rawPayments.total;
    preparePaymentFilters(cleanedPaymentData);
    return cleanedPaymentData;
  }

  return {
    totalPayments,
    allPayments,
    paymentFilters,
    setAllPayments,
    getPaymentData,
    preparePaymentFilters,
    resetFilterSelection,
    resetPaymentStore,
    setFilterCategory,
  };
});

export default usePaymentsStore;
