import { SearchTypes } from '@/types/searchService';
import moment from 'moment';
import { PortalServiceTypes } from '@/types/portalService';

export const handleSearchServiceResponseMapping = (
  response: SearchTypes.SearchResponse,
) => {
  const data = response;
  const { total } = data.hits;
  const hits = data.hits.hits.map(hit => hit._source);
  return {
    hits,
    total,
  };
};

export const determineSearchType = (
  filterString: string,
  range: { startDate: string | undefined; endDate: string | undefined } = {
    startDate: '',
    endDate: '',
  },
): any[] => {
  const filter = [];

  if (filterString.length > 0) {
    if (filterString.length < 9) {
      filter.push({ wildcard: { id: `${filterString}*` } });
    } else if (filterString.length >= 36) {
      filter.push({ match_phrase: { id: filterString } });
    } else {
      filter.push({ match_phrase: { id: `${filterString.slice(0, 8)}` } });
    }
  }

  if (range.startDate && range.endDate) {
    const endDate = new Date(range.endDate);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    const startDate = new Date(range.startDate);
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);

    filter.push({
      range: {
        createdAt: {
          lte: moment.utc(endDate).format(),
        },
      },
    });

    filter.push({
      range: {
        createdAt: {
          gte: moment.utc(startDate).format(),
        },
      },
    });
  }

  return filter;
};

export const handleQueryConstruction = (
  request: SearchTypes.QueryRequest,
  tableName: string,
): PortalServiceTypes.GetDataPayload => ({
  query: {
    query: {
      bool: {
        should: request.should,
        must: request.must,
        filter: determineSearchType(request.filter.idFilter, {
          startDate: request.filter.range?.startDate,
          endDate: request.filter.range?.endDate,
        }),
      },
    },
    from: request.startIndex,
    size: request.size,
    sort: request.sort,
  },
  index: tableName,
});
