export namespace FeeTypes {
  export interface ModalDetails {
    currencyLocal: string;
    amountLocal: string;
    amountUsd: number;
    paymentMethod: string;
    transactionId: string;
    transactionType: string;
    date: string;
    id: string;
  }

  export const rawHeaders = [
    'id',
    'amountLocal',
    'amountUSD',
    'createdAt',
    'currencyLocal',
    'partnerId',
    'paymentMethod',
    'transactionId',
    'transactionType',
    'updatedAt',
  ];
  export const headerMapping = {
    id: 'ID',
    createdAt: 'Date',
    currency: 'Currency',
    transactionType: 'Transaction Type',
    paymentMethod: 'Payment Method',
  };

  export const displayHeaders = [
    'ID',
    'Date',
    'Amount',
    'Transaction Type',
    'Payment Method',
    'View',
  ];
}
