import { defineStore } from 'pinia';
import { handleQueryConstruction } from '@/utils/searchService';
import { reactive, ref } from 'vue';
import {
  addToUniqueArray,
  getUniqueElements,
  prepareMatchQueryParameter,
  prepareTransactionData,
} from '@/utils/tables';
import { TransactionTypes } from '@/types/tables/transactions';
import PortalService from '@/common/services/portal.service';
import { PortalServiceTypes } from '@/types/portalService';

const settlementIndexName = process.env.VUE_APP_SETTLEMENT_INDEX;
export const useSettlementsStore = defineStore('settlements', () => {
  const totalSettlements = ref(0);
  const allSettlements = reactive<any[]>([]);
  const settlementFilters = reactive<TransactionTypes.FilterCategory[]>([
    {
      name: TransactionTypes.Filters.STATUS,
      options: [],
      filter: '',
    },
  ]);

  function resetFilterSelection() {
    const updatedFilterCategories = settlementFilters.map(filterCategory => ({
      ...filterCategory,
      filter: '',
      options: [],
    }));
    settlementFilters.splice(0);
    updatedFilterCategories.forEach(item => settlementFilters.push(item));
  }

  function resetSettlementStore() {
    totalSettlements.value = 0;
    allSettlements.splice(0);
    resetFilterSelection();
  }

  function setFilterCategory(data: string[], filter: TransactionTypes.Filters) {
    const updatedFilterCategories = settlementFilters.map(filterCategory => {
      if (filterCategory.name === filter) {
        return {
          ...filterCategory,
          options: data,
        };
      }
      return filterCategory;
    });
    settlementFilters.splice(0);
    updatedFilterCategories.forEach(item => settlementFilters.push(item));
  }

  function setAllSettlements(data: any[]) {
    allSettlements.splice(0);
    data.forEach(item => allSettlements.push(item));
  }

  function prepareSettlementFilters(data: any[]) {
    const uniqueStatuses = getUniqueElements(
      data,
      TransactionTypes.Filters.STATUS,
    );
    const existingStatusArray = settlementFilters.find(
      category => category.name === TransactionTypes.Filters.STATUS,
    )?.options;

    if (!existingStatusArray) {
      throw new Error('required category status not found in filter object');
    }

    const statusArray = addToUniqueArray(existingStatusArray, uniqueStatuses);
    statusArray.filter(n => n);
    if (existingStatusArray.length !== statusArray.length)
      setFilterCategory(statusArray, TransactionTypes.Filters.STATUS);
  }

  async function getSettlementData(
    partnerId: string,
    token: string,
    filterQuery: TransactionTypes.FilterQuery,
    filterTransactions: TransactionTypes.FilterCategory[],
  ): Promise<any[]> {
    const request = {
      startIndex: filterQuery.startIndex,
      size: 10,
      sort: [{ createdAt: 'desc' }],
      should: [],
      must: prepareMatchQueryParameter(
        partnerId,
        filterTransactions,
        TransactionTypes.DisplayTable.SETTLEMENTS,
      ),
      filter: filterQuery,
    };
    let rawSettlements: PortalServiceTypes.GetDataResponse;
    try {
      const query = handleQueryConstruction(request, `${settlementIndexName}`);
      rawSettlements = await PortalService.GetData(token, query);
    } catch (e: any) {
      console.error(e.message);
      throw new Error('Could not retrieve settlement records');
    }

    const cleanedSettlementData = prepareTransactionData(
      rawSettlements.data,
      TransactionTypes.TransactionTable.SETTLEMENTS,
    );
    totalSettlements.value = rawSettlements.total;
    prepareSettlementFilters(cleanedSettlementData);
    return cleanedSettlementData;
  }

  return {
    totalSettlements,
    allSettlements,
    settlementFilters,
    setAllSettlements,
    getSettlementData,
    prepareSettlementFilters,
    resetFilterSelection,
    resetSettlementStore,
  };
});

export default useSettlementsStore;
