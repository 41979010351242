import { useAuthStore } from '@/store/modules/auth';
import { usePartnerStore } from '@/store/modules/partner';
import { useTransactionsStore } from '@/store/modules/transactions';

export const logoutUser = () => {
  const authStore = useAuthStore();
  const partnerStore = usePartnerStore();
  const transactionStore = useTransactionsStore();

  transactionStore.resetTransactionsStores();
  authStore.resetAuthStore();
  partnerStore.resetPartnerStore();
  partnerStore.resetLowBalanceBanner();
  partnerStore.resetReviewWalletBanner();
  partnerStore.resetPayoutConfig();
};

export default {};
