import { defineStore, storeToRefs } from 'pinia';
import { AccountsTypes } from '@/types/accounts';
import PortalService from '@/common/services/portal.service';
import { reactive } from 'vue';
import { PortalServiceTypes } from '@/types/portalService';
import { useAuthStore } from './auth';

export const useAccountsStore = defineStore('accounts', () => {
  const authStore = useAuthStore();

  const { user } = storeToRefs(authStore);

  const account = reactive<AccountsTypes.Account>({
    partner: { id: '', name: '' },
    accounts: [],
  });

  const setUser = (data: PortalServiceTypes.User) => {
    user.value = data;
  };

  const setAccount = (data: AccountsTypes.Account) => {
    account.partner = data.partner;
    account.accounts = data.accounts;
  };

  const getAccountData = async () => {
    if (!user.value || !user.value.token) {
      throw new Error('cannot retrieve account data if user is undefined');
    }
    const res = await PortalService.GetAccount(user.value.token);
    setAccount(res);
  };

  return {
    account,
    getAccountData,
    setAccount,
    setUser,
  };
});

export default useAccountsStore;
