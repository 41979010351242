import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import { TransactionTypes } from '@/types/tables/transactions';
import {
  addToUniqueArray,
  getUniqueElements,
  prepareMatchQueryParameter,
  prepareTransactionData,
} from '@/utils/tables';
import { handleQueryConstruction } from '@/utils/searchService';
import PortalService from '@/common/services/portal.service';
import { PortalServiceTypes } from '@/types/portalService';

const collectionIndexName = process.env.VUE_APP_COLLECTION_INDEX;

export const useCollectionsStore = defineStore('collections', () => {
  const totalCollections = ref(0);
  const allCollections = reactive<any[]>([]);
  const collectionFilters = reactive<TransactionTypes.FilterCategory[]>([
    {
      name: TransactionTypes.Filters.COUNTRY,
      options: [],
      filter: '',
    },
    {
      name: TransactionTypes.Filters.STATUS,
      options: [],
      filter: '',
    },
    {
      name: TransactionTypes.Filters.REQUEST_SOURCE,
      options: [],
      filter: '',
    },
  ]);

  function resetFilterSelection() {
    const updatedFilterCategories = collectionFilters.map(filterCategory => ({
      ...filterCategory,
      filter: '',
    }));
    collectionFilters.splice(0);
    updatedFilterCategories.forEach(item => collectionFilters.push(item));
  }

  function resetCollectionStore() {
    totalCollections.value = 0;
    allCollections.splice(0);
    resetFilterSelection();
  }

  function setFilterCategory(data: string[], filter: TransactionTypes.Filters) {
    const updatedFilterCategories = collectionFilters.map(filterCategory => {
      if (filterCategory.name === filter) {
        return {
          ...filterCategory,
          options: data,
        };
      }
      return filterCategory;
    });
    collectionFilters.splice(0);
    updatedFilterCategories.forEach(item => collectionFilters.push(item));
  }

  function setAllCollections(data: any[]) {
    allCollections.splice(0);
    data.forEach(item => allCollections.push(item));
  }

  function prepareCollectionFilters(data: any[]) {
    const uniqueCountries = getUniqueElements(
      data,
      TransactionTypes.Filters.COUNTRY,
    );
    const existingCountryArray = collectionFilters.find(
      category => category.name === TransactionTypes.Filters.COUNTRY,
    )?.options;

    const uniqueStatuses = getUniqueElements(
      data,
      TransactionTypes.Filters.STATUS,
    );

    const uniqueSources = getUniqueElements(
      data,
      TransactionTypes.Filters.REQUEST_SOURCE,
    );
    const existingSourcesArray = collectionFilters.find(
      category => category.name === TransactionTypes.Filters.REQUEST_SOURCE,
    )?.options;

    const existingStatusArray = collectionFilters.find(
      category => category.name === TransactionTypes.Filters.STATUS,
    )?.options;

    if (
      !existingCountryArray ||
      !existingStatusArray ||
      !existingSourcesArray
    ) {
      throw new Error('required category not found in filter object');
    }

    let countryArray = addToUniqueArray(existingCountryArray, uniqueCountries);
    countryArray = countryArray.filter(n => n !== null);
    if (existingCountryArray.length !== countryArray.length)
      setFilterCategory(countryArray, TransactionTypes.Filters.COUNTRY);

    let statusArray = addToUniqueArray(existingStatusArray, uniqueStatuses);
    statusArray = statusArray.filter(n => n);
    if (existingStatusArray.length !== statusArray.length)
      setFilterCategory(statusArray, TransactionTypes.Filters.STATUS);

    let sourcesArray = addToUniqueArray(existingSourcesArray, uniqueSources);
    sourcesArray = sourcesArray.filter(n => n);
    if (existingSourcesArray.length !== sourcesArray.length)
      setFilterCategory(sourcesArray, TransactionTypes.Filters.REQUEST_SOURCE);
  }

  async function getCollectionData(
    partnerId: string,
    token: string,
    filterQuery: TransactionTypes.FilterQuery,
    filterTransactions: TransactionTypes.FilterCategory[],
  ): Promise<any[]> {
    const request = {
      startIndex: filterQuery.startIndex,
      size: 10,
      sort: [{ createdAt: 'desc' }],
      should: [],
      must: prepareMatchQueryParameter(
        partnerId,
        filterTransactions,
        TransactionTypes.DisplayTable.COLLECTIONS,
      ),
      filter: filterQuery,
    };
    let rawCollections: PortalServiceTypes.GetDataResponse;
    try {
      const query = handleQueryConstruction(request, `${collectionIndexName}`);
      rawCollections = await PortalService.GetData(token, query);
    } catch (e: any) {
      console.error(e.message);
      throw new Error('Could not retrieve collection records');
    }

    const cleanedCollectionData = prepareTransactionData(
      rawCollections.data,
      TransactionTypes.TransactionTable.COLLECTIONS,
    );
    totalCollections.value = rawCollections.total;
    prepareCollectionFilters(cleanedCollectionData);
    return cleanedCollectionData;
  }

  return {
    totalCollections,
    allCollections,
    collectionFilters,
    setAllCollections,
    getCollectionData,
    prepareCollectionFilters,
    resetFilterSelection,
    resetCollectionStore,
  };
});

export default useCollectionsStore;
