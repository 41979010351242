import FingerPrint from '@/utils/fp';
import axios from 'axios';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/modules/auth';

export async function getDeviceId(): Promise<string> {
  const authStore = useAuthStore();
  const { deviceId } = storeToRefs(authStore);
  if (deviceId && deviceId.value) {
    return deviceId.value;
  }
  const { deviceId: fpDeviceId } = await FingerPrint.get();
  return fpDeviceId;
}

export async function fetchNewEncryptionKey(): Promise<string> {
  const deviceid = await getDeviceId();
  try {
    const api = axios.create({
      withCredentials: false,
      headers: {
        'Access-Control-Allow-Origin': '*',
        deviceid,
      },
    });
    const response = await api.get(
      process.env.VUE_APP_ENCRYPTION_ENDPOINT as string,
    );
    if (response && response.data) {
      const { data } = response;
      if (data && data.csrf) {
        return data.csrf;
      }
    }
  } catch (e) {
    console.error(e);
    return '';
  }
  return '';
}

export async function getEncryptionKey(): Promise<string> {
  const csrfTokenInput = document.querySelector(
    'input[name="_csrf"]',
  ) as HTMLInputElement | null;
  if (csrfTokenInput && csrfTokenInput.value) {
    return csrfTokenInput.value;
  }
  return fetchNewEncryptionKey();
}

export async function setNewEncryptionToken(): Promise<void> {
  const encryptionKey = await fetchNewEncryptionKey();
  if (encryptionKey) {
    const csrfInput = document.getElementById(
      '_csrf',
    ) as HTMLInputElement | null;
    if (csrfInput) {
      csrfInput.value = encryptionKey;
      return;
    }
    const tokenElement = document.createElement('input');
    tokenElement.type = 'hidden';
    tokenElement.name = '_csrf';
    tokenElement.id = '_csrf';
    tokenElement.value = encryptionKey;
    document.body.appendChild(tokenElement);
  }
}
