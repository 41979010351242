<template>
  <Dialog :open="isOpen" :width="395" @close-dialog="$emit('closeDialog')">
    <template v-slot:content>
      <div class="inactive-container">
        <div class="inactive-heading">
          <h4>
            {{ $t('inactiveModal.headingText') }}
          </h4>
        </div>
        <div class="inactive-subheading">
          <p>
            {{ $t('inactiveModal.caption') }}
          </p>
          <v-idle :duration="10" style="margin-top: -1em" @idle="logOut()" />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Dialog } from '@yellowcard/components-library-vue3';
import router from '@/router';
import { logoutUser } from '@/utils/logout';

export default defineComponent({
  name: 'InactiveModal',
  components: {
    Dialog,
  },
  props: {
    isOpen: Boolean,
  },
  setup() {
    const logOut = async () => {
      logoutUser();
      await router.push('login');
      sessionStorage.clear();
    };
    return {
      logOut,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/common/styles/base';

.inactive {
  &-container {
    text-align: center;
  }

  &-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }

  &-subheading {
    font-size: 17px;
    line-height: 28px;
    margin-top: -2em;
    width: 75%;
    display: inline-block;
  }
}
</style>
