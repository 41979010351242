<template>
  <div class="review-wallet-modal">
    <Dialog
      :open="showDialog"
      :width="460"
      @close-dialog="
        $emit('closeDialog');
        step = 1;
        selectedReason = '';
        enteredDenyReason = '';
      "
      class="z-4"
    >
      <template v-slot:content>
        <div class="review-wallet-container" v-if="step === 1">
          <div class="review-wallet-header">
            <img
              class="flex mx-auto mb-8"
              :src="require('@/assets/walletCoins.svg')"
              alt="wallet logo"
            />
            <span class="review-wallet-header-title">
              {{ $t('reviewWalletModal.headingText') }}
            </span>
            <span class="review-wallet-header-subtitle">
              {{ $t('reviewWalletModal.subHeadingText') }}
            </span>
          </div>
          <div class="review-wallet-body">
            <div class="review-wallet-body-row">
              <p class="review-wallet-body-row-key">Asset</p>
              <p class="review-wallet-body-row-value">
                {{ address.cryptocurrency }}
              </p>
            </div>
            <div class="review-wallet-body-row">
              <p class="review-wallet-body-row-key">Network</p>
              <p class="review-wallet-body-row-value">{{ address.network }}</p>
            </div>
            <div class="review-wallet-body-row">
              <p class="review-wallet-body-row-key">Wallet address</p>
              <p class="review-wallet-body-row-value">{{ address.address }}</p>
            </div>
            <div class="review-wallet-body-row">
              <p class="review-wallet-body-row-key">Requested by</p>
              <p class="review-wallet-body-row-value">
                {{ address.createdBy }}
              </p>
            </div>
          </div>
          <div class="review-wallet-footer">
            <div class="review-wallet-button">
              <Button
                text="Deny"
                :radius="10"
                variant="outline"
                @buttonClicked="step = 2"
                id="review-wallet-deny"
              />
            </div>
            <div class="review-wallet-button">
              <Button
                text="Approve"
                :radius="10"
                @buttonClicked="approveOrDenyWallet('approved')"
                id="review-wallet-approve"
                :loader="statusChanging"
              />
            </div>
          </div>
        </div>
        <div class="review-wallet-container" v-if="step === 2">
          <div class="review-wallet-logo">
            <img
              class="flex mx-auto mb-8"
              :src="require('@/assets/handBlock.svg')"
              alt="Yellowcard Logo"
            />
          </div>
          <div class="review-wallet-reason-title">
            {{ $t('reviewWalletModal.denyWalletHeader') }}
          </div>
          <template v-for="reason in denyWalletReasons" :key="reason">
            <div class="review-wallet-reason-option">
              <div class="review-wallet-reason-option-text">
                {{ reason }}
              </div>
              <div class="review-wallet-reason-option-radio">
                <label :for="`${reason}`">
                  <input
                    type="radio"
                    name="settlementNetwork"
                    :value="`${reason}`"
                    :id="`${reason}`"
                    @change="selectReason"
                    class="review-wallet-reason-option-radio-button"
                  />
                  <span class="radio-button"></span>
                </label>
              </div>
            </div>
          </template>
          <div class="review-wallet-reason-other">
            <WidgetInput
              type="text"
              id="review-wallet-reason"
              name="review-wallet-reason"
              aria-label="label"
              placeholder="Other"
              v-model="enteredDenyReason"
            />
          </div>
          <div class="review-wallet-reason-button">
            <Button
              text="Submit"
              :radius="10"
              :disabled="isButtonDisabled"
              @buttonClicked="approveOrDenyWallet('denied')"
              id="review-wallet-deny"
              :loader="statusChanging"
            />
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRefs } from 'vue';
import {
  Dialog,
  Button,
  WidgetInput,
} from '@yellowcard/components-library-vue3';
import { useUiStore } from '@/store/modules/ui';
import { useWalletsStore } from '@/store/modules/wallets';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ReviewWallet',
  components: {
    Dialog,
    Button,
    WidgetInput,
  },
  props: {
    showDialog: Boolean,
    wallet: {
      type: Object,
      required: true,
    },
  },
  emits: ['closeDialog'],
  setup(props, { emit }) {
    const { wallet: address } = toRefs(props);
    const step = ref(1);

    const uiStore = useUiStore();
    const walletsStore = useWalletsStore();
    const { denyWalletReasons } = storeToRefs(walletsStore);
    const selectedReason = ref('');
    const enteredDenyReason = ref('');
    const statusChanging = ref(false);

    const selectReason = (evt: any) => {
      selectedReason.value = evt.target.value;
    };

    const denyReason = computed(() =>
      selectedReason.value !== ''
        ? selectedReason.value
        : enteredDenyReason.value,
    );

    const isButtonDisabled = computed(() => denyReason.value === '');

    async function approveOrDenyWallet(status: string) {
      statusChanging.value = true;
      await walletsStore
        .UpdateWalletStatus(
          props.wallet.address,
          status,
          status === 'denied' ? denyReason.value : undefined,
        )
        .then(() => {
          walletsStore.GetSettlementWallets();
          uiStore.showInfoToast({
            show: true,
            message:
              status === 'approved'
                ? `New wallet address approved and is pending screening. You'll be notified once approved.`
                : 'New wallet address denied',
            duration: 3000,
          });
        })
        .catch(error => {
          uiStore.showToast({
            show: true,
            message: error.message,
          });
        });
      statusChanging.value = false;
      emit('closeDialog');
    }

    return {
      address,
      approveOrDenyWallet,
      step,
      denyWalletReasons,
      selectReason,
      selectedReason,
      enteredDenyReason,
      isButtonDisabled,
      statusChanging,
    };
  },
});
</script>

<style lang="scss">
@import '@/common/styles/_base.scss';

.review-wallet {
  &-container {
    display: flex;
    flex-direction: column;
  }

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 26%;
    margin: 2%;

    &-title {
      font-weight: 700;
      font-size: 24px;
      color: $brand-black;
    }

    &-subtitle {
      font-weight: 400;
      font-size: 16px;
    }
  }

  &-body {
    height: 36%;
    margin: 2%;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fafafa;
    border-radius: 20px;
    padding: 5px 15px;
    font-size: 14px;

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 2px 0px;
      gap: 50px;

      &-key {
        color: #616161;
        width: 30%;
      }

      &-value {
        color: #000000;
        word-wrap: break-word;
        width: 50%;
        font-weight: 500;
      }
    }
  }

  &-reason {
    &-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 24px;
      text-align: center;
    }

    &-other {
      width: 94%;
      margin: 3%;
    }

    &-button {
      width: 94%;
      margin: 3%;

      & .yc--button,
      .yc--button:hover {
        border: none !important;
      }
    }

    &-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 40px;
      margin: 5px 0 5px 10px;

      &-text {
        font-weight: 500;
        font-size: 16px;
        color: $brand-black;
      }

      &-radio {
        width: 10%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;

        & input[type='radio'] {
          position: absolute;
          opacity: 0;
          cursor: pointer;

          &:checked {
            + .radio-button {
              border: 5px solid $brand-primary-yellow-400;
            }
          }
        }

        &-button {
          display: inline-block;
          position: relative;
          cursor: pointer;
          user-select: none;
          border: 5px solid $brand-grey-400;
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }

        & .radio-button {
          position: relative;
          top: 0;
          left: 0;
          height: 10px;
          width: 10px;
          background-color: $brand-white;
          border-radius: 50%;
          clip-path: circle();
          transition: background-color 0.1s;
          display: inline-block;
          border: 5px solid $brand-grey-400;
          cursor: pointer;

          &::after {
            content: '';
            position: absolute;
            display: none;
          }

          &::after {
            top: 50%;
            left: 50%;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: black;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }

  &-footer {
    height: 26%;
    width: 100%;
    margin: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 16px;
  }

  &-button {
    width: 50%;
  }
}
</style>
