import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';
import { TransactionTypes } from '@/types/tables/transactions';
import {
  addToUniqueArray,
  getUniqueElements,
  prepareMatchQueryParameter,
  prepareTransactionData,
} from '@/utils/tables';
import { handleQueryConstruction } from '@/utils/searchService';
import PortalService from '@/common/services/portal.service';

const feesIndexName = process.env.VUE_APP_FEES_INDEX;

export const useFeesStore = defineStore('fees', () => {
  const totalFees = ref(0);
  const allFees = reactive<any[]>([]);
  const feesFilters = reactive<TransactionTypes.FilterCategory[]>([
    {
      name: TransactionTypes.Filters.PAYMENT_METHOD,
      options: [],
      filter: '',
    },
    {
      name: TransactionTypes.Filters.TRANSACTION_TYPE,
      options: [],
      filter: '',
    },
  ]);

  function resetFilterSelection() {
    const updatedFilterCategories = feesFilters.map(filterCategory => ({
      ...filterCategory,
      filter: '',
      options: [],
    }));
    feesFilters.splice(0);
    updatedFilterCategories.forEach(item => feesFilters.push(item));
  }

  function resetFeesStore() {
    totalFees.value = 0;
    allFees.splice(0);
    resetFilterSelection();
  }

  function setFilterCategory(data: string[], filter: TransactionTypes.Filters) {
    const updatedFilterCategories = feesFilters.map(filterCategory => {
      if (filterCategory.name === filter) {
        return {
          ...filterCategory,
          options: data,
        };
      }
      return filterCategory;
    });
    feesFilters.splice(0);
    updatedFilterCategories.forEach(item => feesFilters.push(item));
  }

  function setAllFees(data: any[]) {
    allFees.splice(0);
    data.forEach(item => allFees.push(item));
  }

  function prepareFeesFilters(data: any[]) {
    const uniqueTransactionTypes = getUniqueElements(
      data,
      TransactionTypes.Filters.TRANSACTION_TYPE,
    );
    const existingTransactionTypeArray = feesFilters.find(
      category => category.name === TransactionTypes.Filters.TRANSACTION_TYPE,
    )?.options;

    const uniquePaymentMethod = getUniqueElements(
      data,
      TransactionTypes.Filters.PAYMENT_METHOD,
    );
    const existingPaymentMethodArray = feesFilters.find(
      category => category.name === TransactionTypes.Filters.PAYMENT_METHOD,
    )?.options;

    if (!existingTransactionTypeArray || !existingPaymentMethodArray) {
      throw new Error('required categories not found in filter object');
    }

    const paymentMethodsArray = addToUniqueArray(
      existingPaymentMethodArray,
      uniquePaymentMethod,
    );
    paymentMethodsArray.filter(n => n);
    if (existingPaymentMethodArray.length !== paymentMethodsArray.length)
      setFilterCategory(
        paymentMethodsArray,
        TransactionTypes.Filters.PAYMENT_METHOD,
      );

    const transactionTypesArray = addToUniqueArray(
      existingTransactionTypeArray,
      uniqueTransactionTypes,
    );
    transactionTypesArray.filter(n => n);
    if (existingTransactionTypeArray.length !== transactionTypesArray.length)
      setFilterCategory(
        transactionTypesArray,
        TransactionTypes.Filters.TRANSACTION_TYPE,
      );
  }

  async function getFeesData(
    partnerId: string,
    token: string,
    filterQuery: TransactionTypes.FilterQuery,
    filterTransactions: TransactionTypes.FilterCategory[],
  ): Promise<any[]> {
    const request = {
      startIndex: filterQuery.startIndex,
      size: 10,
      sort: [{ createdAt: 'desc' }],
      should: [],
      must: prepareMatchQueryParameter(
        partnerId,
        filterTransactions,
        TransactionTypes.DisplayTable.FEES,
      ),
      filter: filterQuery,
    };
    let rawFees: any;
    try {
      const query = handleQueryConstruction(request, `${feesIndexName}`);
      rawFees = await PortalService.GetData(token, query);
    } catch (e: any) {
      console.error(e.message);
      throw new Error('Could not retrieve fees records');
    }

    const cleanedFeesData = prepareTransactionData(
      rawFees.data,
      TransactionTypes.TransactionTable.FEES,
    );
    totalFees.value = rawFees.total;
    prepareFeesFilters(cleanedFeesData);
    return cleanedFeesData;
  }

  return {
    totalFees,
    allFees,
    feesFilters,
    setAllFees,
    getFeesData,
    prepareFeesFilters,
    resetFilterSelection,
    resetFeesStore,
  };
});

export default useFeesStore;
