import { defineStore } from 'pinia';
import router from '@/router';
import { logoutUser } from '@/utils/logout';
import PortalService from '@/common/services/portal.service';
import { PortalServiceTypes } from '@/types/portalService';
import { computed, ref } from 'vue';
import VueJwtDecode from 'vue-jwt-decode';
import ChallengeName = PortalServiceTypes.ChallengeName;

export const useAuthStore = defineStore('auth', () => {
  const user = ref<PortalServiceTypes.User>();
  const temporaryUserLogin = ref<PortalServiceTypes.TemporaryUserLogin>();
  const cognitoUser = ref<PortalServiceTypes.CognitoUser>();
  const refreshToken = ref('');
  const currentStep = ref(0);
  const totalSteps = ref(0);
  const isLoggedIn = ref(false);
  const verified = ref(false);
  const sessionToken = ref('');
  const username = ref('');
  const mfaSecret = ref('');
  const challenge = ref('');
  const tempPassword = ref('');
  const deviceId = ref();
  const isAdmin = computed(
    () => user.value?.role === PortalServiceTypes.Role.ADMIN,
  );
  const isTreasury = computed(
    () => user.value?.role === PortalServiceTypes.Role.TREASURY,
  );

  function resetAuthStore() {
    user.value = undefined;
    temporaryUserLogin.value = undefined;
    cognitoUser.value = undefined;
    refreshToken.value = '';
    currentStep.value = 0;
    totalSteps.value = 0;
    isLoggedIn.value = false;
    verified.value = false;
    sessionToken.value = '';
    username.value = '';
    mfaSecret.value = '';
    challenge.value = '';
  }

  function setIsLoggedIn(newLoggedInState: boolean) {
    isLoggedIn.value = newLoggedInState;
  }

  function setVerified(newVerifiedState: boolean) {
    verified.value = newVerifiedState;
  }

  function setSessionToken(newToken: string) {
    sessionToken.value = newToken;
  }

  function setMFASecret(newSecret: string) {
    mfaSecret.value = newSecret;
  }

  function setChallenge(newChallenge: string) {
    challenge.value = newChallenge;
  }

  function setUsername(newUsername: string) {
    username.value = newUsername;
  }

  function setCurrentStep(step: number) {
    currentStep.value = step;
  }

  function setTotalSteps(step: number) {
    totalSteps.value = step;
  }

  function setUserState(state: PortalServiceTypes.User) {
    user.value = state;
  }

  function setTemporaryUserLoginState(
    state: PortalServiceTypes.TemporaryUserLogin,
  ) {
    temporaryUserLogin.value = state;
  }

  function setCognitoUserState(state: PortalServiceTypes.CognitoUser) {
    cognitoUser.value = state;
  }

  async function ConfirmPasswordReset(
    email: string,
    password: string,
    confirmationCode: string,
  ) {
    try {
      await PortalService.ConfirmPasswordReset({
        email,
        password,
        confirmationCode,
      });
    } catch (e: any) {
      throw new Error(`${e.message}`);
    }
  }

  async function CheckAccessToken() {
    try {
      if (cognitoUser.value && refreshToken.value && user.value) {
        const refreshAccessTokenResponse =
          await PortalService.RefreshAccessToken({
            refreshToken: refreshToken.value,
          });
        const updatedUser: PortalServiceTypes.User = {
          ...user.value,
          token: refreshAccessTokenResponse.accessToken,
        };
        const updatedCognitoUser: PortalServiceTypes.CognitoUser = {
          ...cognitoUser.value,
          exp: cognitoUser.value.exp + refreshAccessTokenResponse.expiry - 100,
        };
        setUserState(updatedUser);
        setCognitoUserState(updatedCognitoUser);
      } else {
        throw new Error('cannot retrieve token if user is undefined');
      }
    } catch (e) {
      // token is invalid, log out the user
      logoutUser();
      await router.push('login');
      sessionStorage.clear();
    }
  }

  async function loginUser(email: string, password: string) {
    try {
      const loginResponse = await PortalService.Login({ email, password });
      switch (loginResponse.challengeName) {
        case PortalServiceTypes.ChallengeName.NEW_PASSWORD_REQUIRED:
          setTemporaryUserLoginState({
            email,
            challengeName: loginResponse.challengeName,
          });
          setUsername(loginResponse.userName);
          setChallenge(loginResponse.challengeName);
          tempPassword.value = password;
          break;
        case PortalServiceTypes.ChallengeName.MFA_SETUP:
          setUsername(loginResponse.userName);
          if (loginResponse.session && loginResponse.mfaSecret) {
            setSessionToken(loginResponse.session);
            setMFASecret(loginResponse.mfaSecret);
            setUsername(loginResponse.userName);
            setChallenge(loginResponse.challengeName);
          } else {
            throw new Error(
              `cannot continue with mfa setup without session token and secret: ${loginResponse}`,
            );
          }
          break;
        case PortalServiceTypes.ChallengeName.SOFTWARE_TOKEN_MFA:
          if (loginResponse.session) {
            setSessionToken(loginResponse.session);
          }
          setUsername(loginResponse.userName);
          setChallenge(loginResponse.challengeName);
          break;
        case PortalServiceTypes.ChallengeName.PASSWORD_RESET_REQUESTED:
          setChallenge(loginResponse.challengeName);
          break;
        default:
          throw new Error(
            `challenge not implemented: ${loginResponse.challengeName}`,
          );
      }
    } catch (e: any) {
      console.log(`error signing in user with email ${email}:`, e);
      throw new Error(e.message);
    }
  }

  async function setPassword(password: string) {
    try {
      await PortalService.SetPassword(
        {
          password,
          email: temporaryUserLogin.value?.email,
          tempPassword: tempPassword.value,
        },
        user.value?.token,
      );
      // if the above was successful then log user in
      if (temporaryUserLogin.value) {
        await loginUser(temporaryUserLogin.value.email, password);
      } else {
        throw new Error(
          'cannot set user password without a temporary user state',
        );
      }
    } catch (e: any) {
      console.error(
        `error changing user - ${temporaryUserLogin.value?.email} password:`,
        e,
      );
      throw new Error(e.message);
    }
  }

  async function verifySoftwareToken(userCode: string) {
    try {
      if (username.value !== '' && sessionToken.value !== '') {
        const verifySoftwareTokenResponse =
          await PortalService.VerifySoftwareToken({
            session: sessionToken.value,
            userCode,
            username: username.value,
          });
        if (!verifySoftwareTokenResponse.challengeResponse.user) {
          throw new Error(
            `user undefined in verify token response : ${verifySoftwareTokenResponse}`,
          );
        } else {
          const cognitoUserFromToken: PortalServiceTypes.CognitoUser =
            VueJwtDecode.decode(
              verifySoftwareTokenResponse.challengeResponse.token,
            );
          setCognitoUserState(cognitoUserFromToken);
          refreshToken.value =
            verifySoftwareTokenResponse.challengeResponse.refreshToken;
          cognitoUserFromToken.token =
            verifySoftwareTokenResponse.challengeResponse.token;
          const userResponse =
            verifySoftwareTokenResponse.challengeResponse.user;
          userResponse.token =
            verifySoftwareTokenResponse.challengeResponse.token;
          setUserState(userResponse);
        }
      } else {
        throw new Error(
          'cannot verify software token without username and sessionToken',
        );
      }
    } catch (e: any) {
      throw new Error(`Encountered error verifying token : ${e.message}`);
    }
  }

  async function respondToAuthChallenge(userCode: string) {
    try {
      if (username.value !== '' && sessionToken.value !== '') {
        const respondToAuthChallengeResponse =
          await PortalService.RespondToAuthChallenge({
            session: sessionToken.value,
            challengeName: ChallengeName.SOFTWARE_TOKEN_MFA,
            username: username.value,
            userCode,
          });
        if (!respondToAuthChallengeResponse.user) {
          throw new Error(
            `user undefined in verify token response : ${respondToAuthChallengeResponse}`,
          );
        } else {
          const cognitoUserFromToken: PortalServiceTypes.CognitoUser =
            VueJwtDecode.decode(respondToAuthChallengeResponse.token);
          setCognitoUserState(cognitoUserFromToken);
          refreshToken.value = respondToAuthChallengeResponse.refreshToken;
          cognitoUserFromToken.token = respondToAuthChallengeResponse.token;
          const userResponse = respondToAuthChallengeResponse.user;
          userResponse.token = respondToAuthChallengeResponse.token;
          setUserState(userResponse);
        }
      } else {
        throw new Error(
          'cannot verify software token without username and sessionToken',
        );
      }
    } catch (e: any) {
      throw new Error(e.message);
    }
  }

  return {
    loginUser,
    user,
    temporaryUserLogin,
    cognitoUser,
    currentStep,
    totalSteps,
    setCurrentStep,
    setTotalSteps,
    setPassword,
    isLoggedIn,
    setIsLoggedIn,
    verified,
    setVerified,
    CheckAccessToken,
    refreshToken,
    mfaSecret,
    challenge,
    verifySoftwareToken,
    username,
    respondToAuthChallenge,
    resetAuthStore,
    ConfirmPasswordReset,
    sessionToken,
    setSessionToken,
    setMFASecret,
    setUsername,
    setChallenge,
    setUserState,
    deviceId,
    isAdmin,
    isTreasury,
  };
});

export default useAuthStore;
