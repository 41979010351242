export namespace PaymentTypes {
  export const rawHeaders = [
    'id',
    'country',
    'convertedAmount',
    'currency',
    'destinationaccountType',
    'status',
    'createdAt',
    'amount',
    'sendername',
    'destinationaccountNumber',
    'sequenceId',
    'rate',
    'sessionId',
  ];
  export const headerMapping = {
    id: 'ID',
    country: 'Country',
    convertedAmount: 'convertedAmount',
    status: 'Status',
    destinationaccountType: 'Payment Method',
    createdAt: 'Date',
    currency: 'Currency',
  };

  export const displayHeaders = [
    'ID',
    'Date',
    'Country',
    'Amount',
    'Status',
    'Payment Method',
    'View',
  ];
}
