export namespace TransactionTypes {
  export interface SettlementInfo {
    cryptoCurrency: string;
    cryptoNetwork: string;
    cryptoAmount: number;
    txHash: string;
  }

  export interface TransactionsDetails {
    fiatCurrency: string;
    fiatAmount: string;
    cryptoCurrency: string;
    cryptoAmount: number;
    type: string;
    status: string;
    senderName: string;
    paymentMethod: string;
    sessionId: string;
    sequenceId: string;
    date: string;
    rate: number;
    id: string;
    settlementInfotxHash: string;
    settlementStatus: string;
    networkFeeAmountUSD: number;
    serviceFeeAmountUSD: number;
    requestSource: string;
  }

  export enum TransactionTable {
    COLLECTIONS = 'collection',
    PAYMENTS = 'payment',
    SETTLEMENTS = 'settlement',
    FEES = 'fees',
  }

  export enum DisplayTable {
    // COMBINED,
    PAYMENTS,
    COLLECTIONS,
    SETTLEMENTS,
    FEES,
  }

  export enum Filters {
    STATUS = 'Status',
    COUNTRY = 'Country',
    PAYMENT_METHOD = 'Payment Method',
    TRANSACTION_TYPE = 'Transaction Type',
    REQUEST_SOURCE = 'Request Source',
  }

  export interface FilterCategory {
    name: Filters;
    options: string[];
    filter: string;
  }

  export interface FilterQuery {
    startIndex: number;
    idFilter: string;
    range: {
      startDate: string;
      endDate: string;
    };
  }
}
