import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import HomeView from '@/views/HomeView.vue';
import { useAuthStore } from '@/store/modules/auth';
import { RouteNames, RoutePaths } from './route-details';

const routes: Array<RouteRecordRaw> = [
  {
    path: RoutePaths.Home,
    redirect: { path: '/transactions' },
    name: RouteNames.Home,
    component: HomeView,
    children: [
      {
        path: RoutePaths.Transactions,
        name: RouteNames.Transactions,
        component: () => import('@/views/dashboard/TransactionsView.vue'),
      },
      {
        path: RoutePaths.Balance,
        name: RouteNames.Balance,
        component: () => import('@/views/dashboard/BalanceView.vue'),
      },
      {
        path: RoutePaths.APIKeys,
        name: RouteNames.APIKeys,
        component: () => import('@/views/dashboard/APIKeyView.vue'),
      },
      {
        path: RoutePaths.Settings,
        name: RouteNames.Settings,
        component: () => import('@/views/dashboard/SettingsView.vue'),
      },
      {
        path: RoutePaths.WalletManagement,
        name: RouteNames.WalletManagement,
        component: () => import('@/views/dashboard/WalletManagementView.vue'),
      },
    ],
  },
  {
    path: RoutePaths.Login,
    name: RouteNames.Login,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/authentication/LoginView.vue'),
  },
  {
    path: RoutePaths.ResetPassword,
    name: RouteNames.ResetPassword,
    component: () => import('@/views/authentication/ResetPasswordView.vue'),
  },
  {
    path: RoutePaths.TwoFactorAuthentication,
    name: RouteNames.TwoFactorAuthentication,
    component: () =>
      import('@/views/authentication/TwoFactorAuthenticationView.vue'),
  },
  {
    path: RoutePaths.PasswordChanged,
    name: RouteNames.PasswordChanged,
    component: () => import('@/views/authentication/PasswordChangedView.vue'),
  },
  {
    path: RoutePaths.AccountInactive,
    name: RouteNames.AccountInactive,
    component: () => import('@/views/authentication/AccountInactiveView.vue'),
  },
  {
    path: RoutePaths.RequestedPasswordReset,
    name: RouteNames.RequestedPasswordReset,
    component: () =>
      import('@/views/authentication/RequestedPasswordResetView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Routes accessible by admin users only
const adminRoutes = [RouteNames.APIKeys];

// Routes accessible by both admin and treasury roles
const treasuryRoutes = [RouteNames.WalletManagement];

// These routes are completely unprotected
const unProtectedRoutes = [
  RouteNames.Login,
  RouteNames.AccountInactive,
  RouteNames.RequestedPasswordReset,
];

// These routes can be seen if a user is logged in but not 2fa verified
const partiallyProtectedRoutes = [
  RouteNames.TwoFactorAuthentication,
  RouteNames.ResetPassword,
];

router.beforeEach((to, from, next) => {
  const isProtected =
    !unProtectedRoutes.includes(<RouteNames>(<string>to.name)) &&
    !partiallyProtectedRoutes.includes(<RouteNames>(<string>to.name));
  const isPartiallyProtected = partiallyProtectedRoutes.includes(
    <RouteNames>(<string>to.name),
  );
  const isAdminRoute = adminRoutes.includes(<RouteNames>(<string>to.name));
  const isTreasuryRoute = treasuryRoutes.includes(
    <RouteNames>(<string>to.name),
  );
  const auth = useAuthStore();

  if (isProtected && !auth.verified) {
    if (isPartiallyProtected && auth.isLoggedIn) {
      next();
    } else if (isPartiallyProtected && !auth.isLoggedIn) {
      next({
        path: '/login',
      });
    }
    next({
      path: '/login',
    });
  } else if ((!isProtected || isPartiallyProtected) && auth.verified) {
    next({
      path: '/',
    });
  } else if (isAdminRoute && !auth.isAdmin) {
    next({
      path: '/',
    });
  } else if (isTreasuryRoute && !auth.isTreasury && !auth.isAdmin) {
    next({
      path: '/',
    });
  }
  next();
});

export default router;
